<template>
  <LayoutTunnel>
    <template slot="left-side">
      <div
        class="flex flex-col flex-1 items-end lg:items-center justify-center px-16 lg:px-8"
      >
        <div class="flex flex-col">
          <div
            class="text-dark-grey-400 font-medium text-3xl lg:text-2xl font-poppins xl:w-4/5 leading-9 mb-4"
          >
            Commencez par saisir l’adresse de votre bien
          </div>
          <div class="flex"></div>
          <div class="grid grid-cols-3 lg:grid-cols-1 gap-2">
            <div class="xl:col-span-2 flex">
              <AutoComplete
                rules="required"
                placeholder="L'adresse du bien"
                class="w-full"
                :list="adresses"
                v-model="form.informations.adresse"
                :args="['properties', 'label']"
                @setItem="setAdress"
                :is_loading="api_gouv_is_loading"
              />
            </div>
            <div>
              <portal-target name="next-prev-step"> </portal-target>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template slot="right-side">
      <div
        class="bg-promy-green-500 bg-opacity-30 flex items-center justify-center lg:hidden"
      >
        <img src="/images/building.svg" alt="" />
      </div>
    </template>
  </LayoutTunnel>
</template>
<script>
export default {
  data() {
    return {
      adresses: [],
      api_gouv_is_loading: false,
    }
  },
  props: {
    form: {
      type: Object,
      require: true,
    },
    cadastre: {
      type: Object,
      require: true,
    },
  },
  watch: {
    'form.informations.adresse': {
      handler() {
        this.searchAfterDebounce()
      },
    },
  },
  created() {
    let coords = this.form.informations.coords_parcelle_principale
    if (coords.length) {
      this.getInfosCadastre(coords)
    }
  },
  methods: {
    setAdress(adresse) {
      if (this.cadastre.old_adresse !== this.form.informations.adresse) {
        this.form.informations.adresse = adresse.properties.label
        this.form.informations.coords_parcelle_principale =
          adresse.geometry.coordinates
        this.form.informations.current_parcelle = null
        this.form.informations.parcelles_voisines = []
        this.getInfosCadastre(adresse.geometry.coordinates)
      }
    },
    searchAfterDebounce: _.debounce(
      function () {
        this.fetchAdresses()
      },
      800, // milliseconds
    ),

    fetchAdresses() {
      this.api_gouv_is_loading = true
      this.$http
        .get('gouvUrl', {
          params: {
            limit: 10,
            q: this.form.informations.adresse,
          },
        })
        .then((response) => {
          this.api_gouv_is_loading = false
          this.adresses = response.data.hasOwnProperty('features')
            ? response.data.features
            : []
        })
    },
    getInfosCadastre(coords) {
      this.cadastre.error_parcelle_not_found = null
      this.cadastre.is_api_loading = true
      let api_cadastre = this.$url_cadastre
        .replace('{long}', coords[0])
        .replace('{lat}', coords[1])
      this.$http
        .get(api_cadastre)
        .then((response) => {
          this.cadastre.data = response.data
          this.form.informations.code_insee = response.data.code_insee
          this.form.informations.code_postal = response.data.code_postal
          this.form.informations.departement = response.data.departement
          this.form.informations.region = response.data.region
          this.cadastre.is_api_loading = false
        })
        .catch((err) => {
          this.cadastre.error_parcelle_not_found =
            'Informations de la parcelle non disponibles'
        })
    },
  },
}
</script>
